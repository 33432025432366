import axios from "axios";
import JwtService from "@/core/services/jwt.service";

const axio = axios.create({
  baseURL: "http://localhost"
});

export function setHeader() {
  axio.headers.Authorization = `token ${JwtService.getToken()}`;
}

export function verify() {
  return axio.get("verify");
}

export function login(data) {
  return axio.post("/login", data);
}

export function setPassword(password) {
  return axio.put("/password", password);
}
